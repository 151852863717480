import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '../../hooks/useQueryParams'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import { camelCase, toInteger } from 'lodash'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import { getPaginationData, parseFilterQuery } from '../../utils/utils'
import {
  ConfirmationDialog,
  Flex,
  FormattedDate,
  Icon,
  Money,
  StatusBadge,
  Text,
  Tooltip,
} from '../../ui-kit'
import { MONEY } from '../../utils/dataTypes'
import { displayStatusesMapping } from './paymentsTab/util'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import PaymentContent from './paymentsTab/PaymentContent'
import MenuButton from '../../components/dataGrid/menuButton/MenuButton'
import PropTypes from 'prop-types'
import NewPaymentModal from './newPayment/NewPaymentModal'
import IssueRefundModal from './issueRefund/IssueRefundModal'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import axios from 'axios'
import getVendorSlug from '../../utils/getVendorSlug'
import colors from '../../ui-kit/colors'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { useNotifications } from '../../hooks/useNotifications'
import { PAYMENT_TRANSACTIONS_KEY } from '../../constants/queryKeys'
import { useCurrentVendor } from '../../hooks/useCurrentVendor'
import { mapFiltersForQuery, mapSelectedFilters } from '../../components/filters/logic/logic'
import { refundLogo } from '../../ui-kit/assets'
import VoidErrorMessageDialog from './VoidErrorMessageDialog'

const PaymentsTab = ({ isNewPaymentModalOpened, setIsNewPaymentModalOpened }) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()
  const { queryParams } = useQueryParams()
  const sort = queryParams.sort || 'created_at.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search
  const { setBreadcrumbs } = useBreadcrumbs()
  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)
  const filters = useMemo(() => mapFiltersForQuery(userFilters), [userFilters])

  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [selectedPaymentTransaction, setSelectedPaymentTransaction] = useState(null)
  const [isVoidModalOpened, setIsVoidModalOpened] = useState(false)
  const [isIssueRefundModalOpened, setIsIssueRefundModalOpened] = useState(false)

  useEffect(() => {
    setBreadcrumbs([{ label: t('payments'), link: '/payments' }, { label: t('payments') }])
  }, [])
  const vendorData = useCurrentVendor()

  const locationsList = useMemo(
    () =>
      vendorData.locations?.map?.((item) => ({
        key: item.id,
        title: item.name,
      })) || [],
    [vendorData?.locations],
  )

  const availableFilters = [
    {
      key: 'status',
      options: [
        { title: 'pending', key: 'pending' },
        { title: 'successful', key: 'successful' },
        { title: 'failed', key: 'failed' },
        { title: 'voided', key: 'voided' },
        { title: 'chargeback', key: 'chargeback' },
      ],
      type: 'select',
    },
    {
      key: 'date',
      type: 'dates_range',
    },
    {
      key: 'payment_method_type',
      options: [
        { key: 'ClearentPaymentMethodConfiguration', title: 'Card' },
        { key: 'ACHQPaymentMethodConfiguration', title: 'Bank Transfer' },
      ],
      type: 'select',
    },
    {
      key: 'location',
      options: locationsList,
      type: 'select',
    },
  ]

  const handleRowClick = (data) => {
    setSelectedPaymentTransaction(data.row)
    setIsSidebarOpened(true)
  }
  const handleOpenRefundModal = (rowData) => {
    setIsSidebarOpened(false)
    setSelectedPaymentTransaction(rowData)
    setIsIssueRefundModalOpened(true)
  }
  const handleOpenVoidModal = (rowData) => {
    setIsSidebarOpened(false)
    setSelectedPaymentTransaction(rowData)
    setIsVoidModalOpened(true)
  }
  const getOptions = (rowData, values) => {
    const options = [
      {
        label: t('viewDetails'),
        action: () => handleRowClick(values),
      },
    ]
    if (rowData.refundableAmountCents) {
      options.push({
        label: t('refund'),
        action: () => handleOpenRefundModal(rowData),
      })
    }
    if (rowData.voidable) {
      options.push({
        label: t('void'),
        action: () => handleOpenVoidModal(rowData),
        validationError: !rowData.voidable,
      })
    }
    return options
  }

  const columns = useMemo(
    () => [
      {
        field: 'createdAt',
        filterId: 'date',
        filterTitle: 'paymentDate',
        headerName: t('date'),
        renderCell: (values) => (
          <FormattedDate date={values?.row?.createdAt} format={'MM/dd/yyyy hh:mm:ss a'} />
        ),
        flex: 14,
      },
      {
        field: 'id',
        filterId: 'id',
        headerName: t('paymentId'),
        flex: 9,
      },
      {
        field: 'accountNumber',
        filterId: 'accountNumber',
        headerName: t('accountNo'),
        flex: 12,
      },
      {
        field: 'companyName',
        headerName: t('accountName'),
        renderCell: (values) => (
          <Tooltip content={values?.row?.companyName}>{values?.row?.companyName}</Tooltip>
        ),
        flex: 12,
      },
      {
        field: 'location',
        filterId: 'location',
        headerName: t('location'),
        flex: 12,
        renderCell: (values) => (
          <Tooltip
            className={'text-ellipsis overflow-hidden'}
            content={values?.row?.location?.name}>
            {values?.row?.location?.name}
          </Tooltip>
        ),
      },
      {
        field: 'amountCents',
        filterId: 'amountCents',
        filterDataType: MONEY,
        headerName: t('amount'),
        flex: 8,
        renderCell: (values) => <Money value={values.row?.amountCents} />,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'chargedAmountCents',
        headerName: t('charged'),
        flex: 8,
        renderCell: (values) => (
          <Money value={values?.row?.amountCents + values?.row?.feeAmountCents} />
        ),
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'blank',
        headerName: '',
        sortable: false,
        flex: 4,
      },
      {
        field: 'paymentMethod',
        filterId: 'paymentMethodType',
        headerName: t('paymentMethod'),
        flex: 12,
        renderCell: (values) => values?.row?.paymentMethod?.title,
      },
      {
        field: 'attachmentIcon',
        headerName: '',
        sortable: false,
        renderCell: (values) =>
          values.row.attachment && <Icon color="text-black-500" name="paperClip2" />,
        flex: 2,
      },
      {
        field: 'status',
        filterId: 'status',
        headerName: t('status'),
        filterTitle: t('status'),
        flex: 9,
        renderCell: (values) => (
          <StatusBadge
            color={displayStatusesMapping[values?.row?.status]?.color}
            value={t(displayStatusesMapping[values?.row?.status]?.label)}
          />
        ),
      },
      {
        field: 'refundIcon',
        headerName: '',
        sortable: false,
        renderCell: (values) =>
          values.row.hasRefund && (
            <div>
              <Tooltip content={t('refunded')}>
                <img alt="Suppli" className="h-8" src={refundLogo} />
              </Tooltip>
            </div>
          ),
        flex: 2,
      },
      {
        field: 'actions',
        sortable: false,
        headerName: '',
        renderCell: (values) => {
          return <MenuButton options={getOptions(values?.row, values)} values={values} />
        },
        flex: 3,
      },
    ],
    [],
  )
  const vendorSlug = getVendorSlug()
  const pageSize = useMemo(() => 100, [])

  const transactionsQueryKey = [PAYMENT_TRANSACTIONS_KEY, sort, search, page, filters]
  const {
    data: transactions,
    loading,
    refetch,
  } = useCustomQuery({
    queryOptions: {
      queryKey: transactionsQueryKey,
      enabled: !!vendorSlug,
      queryFn: () =>
        axios.get(`${vendorSlug}/payment_transactions`, {
          params: { sort, search, filters, page },
        }),
    },
    rollbarOptions: { operationName: 'payments_transactions', target: 'PaymentsTab' },
  })

  const rows = transactions?.data || []
  const paginationData = getPaginationData(transactions)

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])

  const handleCancelVoidPayment = useCallback(() => {
    setIsSidebarOpened(true)
    setIsVoidModalOpened(false)
  }, [])

  const [isVoidErrorModalOpened, setIsVoidErrorModalOpened] = useState(false)
  const [voidErrorCode, setVoidErrorCode] = useState()

  const [handleVoid, { loading: voidInProgress }] = useCustomMutation({
    onCompleted: (voidedTransaction) => {
      updateQuery({ queryKey: transactionsQueryKey, record: voidedTransaction })
      newNotification({ success: t('transactionHasBeenVoided') })
      setIsVoidModalOpened(false)
    },
    customErrorHandling: (error) => {
      const parsedError = error.response?.data?.errors?.base
      const errorCode = parsedError?.length ? parsedError[0]['error_code'] : null
      setIsVoidModalOpened(false)
      if (errorCode) {
        setIsVoidErrorModalOpened(true)
        setVoidErrorCode(errorCode)
      } else {
        if (parsedError) {
          newNotification({ error: parsedError.map((item) => item.details)?.join(', ') })
        }
      }
    },
    rollbarOptions: { operationName: 'void_payment_transaction', target: 'PaymentsTab' },
    mutationOptions: {
      mutationFn: () =>
        axios.patch(`${vendorSlug}/payment_transactions/${selectedPaymentTransaction.id}/void`),
      mutationKey: ['void_payment_transaction'],
    },
  })

  const [refundTransaction, { loading: refundInProgress }, updateQuery] = useCustomMutation({
    onCompleted: () => {
      refetch()
      // TODO make partial update - need to fetch the updated transaction
      // updateQuery({ queryKey: transactionsQueryKey, record: refundedTransaction })
      newNotification({ success: t('refundSubmittedSuccessfully') })
      setIsIssueRefundModalOpened(false)
    },
    rollbarOptions: { operationName: 'refund_transactions', target: 'PaymentsTab' },
    mutationOptions: {
      enabled: !!selectedPaymentTransaction?.id,
      mutationFn: (values) =>
        axios.post(
          `${vendorSlug}/payment_transactions/${selectedPaymentTransaction?.id}/refund_transactions`,
          {
            refundTransaction: { ...values },
          },
        ),
      mutationKey: ['refund_transactions'],
    },
  })

  return (
    <>
      <div className="pt-4">
        <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened}>
          <PaymentContent
            id={selectedPaymentTransaction?.id}
            paymentMethod={selectedPaymentTransaction?.paymentMethod?.title}
            refundTransaction={handleOpenRefundModal}
            transaction={selectedPaymentTransaction}
            voidTransaction={handleOpenVoidModal}
          />
        </Sidebar>

        <DataGridComponent
          availableFilters={availableFilters}
          checkboxSelection={false}
          columns={columns}
          loading={loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rowClassName="cursor-pointer"
          rowClick={handleRowClick}
          rows={rows}
          searchLabel={t('payments')}
          sortModel={defaultSortModel}
          userFilters={mapSelectedFilters(userFilters)}
        />
      </div>

      <NewPaymentModal
        isOpened={isNewPaymentModalOpened}
        refetchPayment={refetch}
        setIsOpened={setIsNewPaymentModalOpened}
      />
      <IssueRefundModal
        isOpened={isIssueRefundModalOpened}
        loading={refundInProgress}
        refundTransaction={refundTransaction}
        setIsOpened={setIsIssueRefundModalOpened}
        transaction={selectedPaymentTransaction}
      />
      <ConfirmationDialog
        confirmationMessage={
          <Flex column>
            {t('voidWarningMsg')}
            <Text
              className="pt-4"
              color={colors.GRAY_700}
              fontWeight={fontWeight.MEDIUM}
              size={sizes.SM}>
              {t('voidsCannotBeUndone')}
            </Text>
          </Flex>
        }
        isOpened={isVoidModalOpened}
        isSubmitButtonYesDisabled={voidInProgress}
        onModalClose={() => setIsSidebarOpened(true)}
        onSubmitButtonNoClick={handleCancelVoidPayment}
        onSubmitButtonYesClick={handleVoid}
        setIsOpened={setIsVoidModalOpened}
        title={t('voidPayment')}
      />
      <VoidErrorMessageDialog
        isVoidModalOpened={isVoidErrorModalOpened}
        onModalClose={() => setIsVoidErrorModalOpened(false)}
        setIsVoidModalOpened={setIsVoidErrorModalOpened}
        voidErrorCode={voidErrorCode}
      />
    </>
  )
}

PaymentsTab.propTypes = {
  isNewPaymentModalOpened: PropTypes.bool,
  setIsNewPaymentModalOpened: PropTypes.func.isRequired,
}

PaymentsTab.defaultProps = {
  isNewPaymentModalOpened: false,
}

export default PaymentsTab
